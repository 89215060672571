<template>
    <div class="board-input">
        <div class="board-input__contents">
            <div class="py-12">
                <v-row no-gutters align="center">
                    <v-col cols="12" md="2" class="board-input__row-header"><span>Title</span></v-col>
                    <v-col cols="12" md="10">
                        <v-text-field v-model="board.subject" @input="$emit('input', board)" outlined hide-details />
                    </v-col>
                </v-row>
            </div>
            <v-divider />

            <div class="py-12">
                <v-row no-gutters align="center">
                    <v-col cols="12" md="2" class="board-input__row-header"><span>Files</span></v-col>
                    <v-col cols="12" md="10">
                        <v-chip-group>
                            <v-chip v-for="file in board?.files" :key="file?.name" small outlined close @click:close="remove(file)">
                                {{ file?.name }}
                            </v-chip>
                            <v-chip v-if="(board?.files?.length || 0) < 2" small fab tile  @click="showFileInput">
                                <v-icon>mdi-plus</v-icon>
                            </v-chip>
                        </v-chip-group>
                        <v-file-input v-model="file" v-show="false" ref="file-input" />
                    </v-col>
                </v-row>
            </div>
            <v-divider />

            <div class="py-12">
                <v-textarea v-model="board.content" outlined hide-details @input="$emit('input', board)" />
            </div>
        </div>

        <div class="board-bottom">
            <v-row justify="end" class="row--x-small">
                <v-col cols="auto">
                    <v-btn small exact color="grey-6" class="w-100px" @click="$router.go(-1)">Cancel</v-btn>
                </v-col>
                <v-spacer />
                <v-col cols="auto">
                    <v-btn small exact color="secondary" class="w-100px" @click="$emit('save')">Confirm</v-btn>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        value: { type: Object, default: null },
    },
    data() {
        return {
            board: this.$props.value,
            file: null,
        };
    },
    watch: {
        value() {
            this.board = this.value;
        },
        file(file) {
            if (file) {
                this.board.files.push(file);
                this.file = null;
            }
        },
    },
    methods: {
        showFileInput() {
            this.$refs["file-input"]?.$el.getElementsByTagName("input")?.[0]?.click?.();
        },
        remove(file) {
            this.board.files = this.board.files.filter((item) => item != file);
        },
    },
};
</script>
